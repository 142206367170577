<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>travel-map-finger</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M12.743,23.248,9.978,20.139a1.557,1.557,0,1,1,2.328-2.069l1.937,2.178v-9a1.5,1.5,0,1,1,3,0V18h1.5a4.5,4.5,0,0,1,4.5,4.5v.75"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M2.616,23.142A12.615,12.615,0,0,1,1.4,20.482"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M.819,17.764a12.9,12.9,0,0,1,.034-2.926"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M1.519,12.138A12.483,12.483,0,0,1,2.836,9.525"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M4.6,7.373A12.625,12.625,0,0,1,6.913,5.586"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.439,4.426a9.353,9.353,0,0,1,1.414-.385"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="14.243"
      y1="0.748"
      x2="18.743"
      y2="5.248"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="18.743"
      y1="0.748"
      x2="14.243"
      y2="5.248"
    />
  </svg>
</template>
